<template>
  <div class="divBox">
    <div class="selCard">
      <el-form
        inline
        size="small"
        :model="tableFrom"
        ref="searchForm"
        label-width="120px"
      >
        <el-form-item label="商品分组:" prop="classifyId">
          <el-cascader
            v-model="tableFrom.classifyId"
            class="selWidth"
            :options="classifyList"
            :props="{
              checkStrictly: true,
              emitPath: false,
              label: 'name',
              value: 'id',
            }"
            clearable
          />
        </el-form-item>
        <el-form-item label="商品名称:" prop="name">
          <el-input
            class="selWidth"
            v-model="tableFrom.name"
            placeholder="请输入商品名称"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="商品状态:" prop="status">
          <el-select
            v-model="tableFrom.status"
            placeholder="请选择"
            class="filter-item selWidth"
            clearable
          >
            <el-option
              v-for="item in productStatusList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="所属品牌:" prop="brandId">
          <el-select
            v-model="tableFrom.brandId"
            clearable
            placeholder="请选择"
            class="selWidth"
          >
            <el-option
              v-for="(item, index) in brandList"
              :key="index"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="所属供应商:" prop="vendorId">
          <el-select
            v-model="tableFrom.vendorId"
            clearable
            placeholder="请选择"
            class="selWidth"
          >
            <el-option
              v-for="(item, index) in supplierList"
              :key="index"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="是否首页推荐:" prop="isRecommendBrand">
          <el-select
            v-model="tableFrom.isRecommendBrand"
            placeholder="请选择"
            class="filter-item selWidth"
            clearable
          >
            <el-option
              v-for="item in statusOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item> -->
        <el-form-item label="是否为E3:" prop="pushType">
          <el-select
            v-model="tableFrom.pushType"
            placeholder="请选择"
            class="filter-item selWidth"
            clearable
          >
            <el-option
              v-for="item in statusOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" size="small" @click="search()"
            >搜索</el-button
          >
          <el-button size="small" @click="searchReset()">重置</el-button>
          <el-button size="small" @click="exportFun()">导出</el-button>
        </el-form-item>
      </el-form>
    </div>

    <el-card class="mt14">
      <el-tabs v-model="tableFrom.checkStatus" @tab-click="tabClick">
        <el-tab-pane
          v-for="(item, index) in tabList"
          :key="index"
          :name="item.id"
          :label="item.name"
        />
      </el-tabs>
      <div style="margin-bottom: 10px">
        <el-button
          v-if="tableFrom.checkStatus == 0"
          size="small"
          @click="batchExamine"
          >批量审核</el-button
        >
        <el-button size="small" type="primary" @click="exportExamineGoods"  v-if="tableFrom.checkStatus == 0"
          >批量导入审核商品</el-button
        >
        <el-button size="small" type="primary" @click="batchUp(1)"
          >批量上架</el-button
        >
        <el-button size="small" type="primary" @click="batchDown(0)"
          >批量下架</el-button
        >
        <el-button size="small" type="primary" @click="exportGoods"
          >商品导入</el-button
        >
      </div>
      <el-table
        v-loading="listLoading"
        :data="tableData.data"
        size="small"
        @selection-change="handleSelectionChange"
      >
        <el-table-column key="2" type="selection" width="55" />
        <el-table-column label="商品图" min-width="70">
          <template slot-scope="scope">
            <div class="demo-image__preview">
              <el-image
                style="width: 36px; height: 36px"
                :src="scope.row.mainImg"
                :preview-src-list="[scope.row.mainImg]"
              />
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="brandName" label="品牌名称" min-width="150" />
        <el-table-column prop="name" label="商品名称" min-width="200">
          <template slot-scope="scope">
            <div>
              <span
                class="tags_name"
                :style="
                  scope.row.specType == 0
                    ? 'color: #ff8a4d;'
                    : 'color: #4073FA;'
                "
                :class="'name' + scope.row.spec_type"
                >{{ scope.row.specType == 0 ? "[单规格]" : "[多规格]" }}</span
              >{{ scope.row.name || "-" }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="classifyNames" label="分类名称" min-width="200">
          <template slot-scope="scope">
            <div v-if="scope.row.firstCategoryName">
              {{
                `${scope.row.firstCategoryName}/${scope.row.secondCategoryName}`
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="classifyNames"
          label="分组名称"
          min-width="150"
        />
        <el-table-column prop="E3" label="是否为E3" min-width="90">
          <template slot-scope="scope">
            <div>{{ scope.row.e3 ? "是" : "否" }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="salePrice" label="商品售价(元)" min-width="90" />
        <el-table-column prop="actualSaleNum" label="销量" min-width="70" />
        <el-table-column prop="stock" label="库存" min-width="70" />

        <el-table-column prop="sort" label="排序" min-width="60" />
        <!-- <el-table-column prop="status" label="是否首页推荐" min-width="80">
          <template slot-scope="scope">
            <div>{{ scope.row.recommend == 1 ? "是" : "否" }}</div>
          </template>
        </el-table-column> -->
        <el-table-column prop="status" label="商品状态" min-width="80">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.status"
              :active-value="1"
              :inactive-value="0"
              @change="onchangeStatus(scope.row)"
            />
          </template>
        </el-table-column>

        <el-table-column
          label="操作"
          fixed="right"
          :min-width="tableFrom.checkStatus == 0 ? 120 : 90"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              icon="el-icon-info"
              size="small"
              @click="onDetails(scope.row.id)"
              >详情</el-button
            >
            <el-button
              v-if="tableFrom.checkStatus == 0"
              type="text"
              icon="el-icon-info"
              size="small"
              @click.native="toExamine(scope.row.id)"
              >审核</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          background
          :page-size="tableFrom.pageSize"
          :current-page="tableFrom.pageNum"
          layout="total, prev, pager, next, jumper"
          :total="tableData.total"
          @size-change="handleSizeChange"
          @current-change="pageChange"
        />
      </div>
    </el-card>
    <pro-detail
      ref="proDetail"
      :productId="product_id"
      @closeDrawer="closeDrawer"
      @changeDrawer="changeDrawer"
      :drawer="drawer"
    ></pro-detail>

    <el-dialog
      title="批量审核"
      :visible.sync="dialogVisible"
      width="600px"
      :before-close="handleClose"
    >
      <el-form>
        <el-form-item prop="checkStatus" label="审核状态:">
          <el-radio-group v-model="checkStatus" @input="checkStatusChange">
            <el-radio :label="1">审核通过</el-radio>
            <el-radio :label="2">审核未通过</el-radio>
          </el-radio-group></el-form-item
        >
        <el-form-item
          v-if="checkStatus == 2"
          prop="checkStatus"
          label="审核未通过原因:"
        >
          <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入内容"
            v-model="checkReason"
          >
          </el-input>
        </el-form-item>
        <el-form-item prop="e3" label="是否为E3商品:" v-if="checkStatus == 1">
          <el-radio-group v-model="e3">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group></el-form-item
        >
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="handleClose">取 消</el-button>
        <el-button size="small" type="primary" @click="checkSumbit"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <Import
      v-if="importShow"
      :show="importShow"
      :action="importAction"
      :downloadLink="importDownloadLink"
      :problemLink="importProblem"
      @close="importClose"
    />
  </div>
</template>

<script>
import {
  goodsAdd,
  goodsCheck,
  goodsDel,
  goodsPageList,
  goodsShelve,
  goodsUpdate,
  goodsView,
  goodBrandListAll,
  goodsSet,
  goodsClassifyList,
  goodBrandTotalListAll,
} from "@/api/goods/goods";
import { getSupplierListAll } from "@/api/supplier/supplier";
import proDetail from "./components/proDetails.vue";
import { exportExcel } from "@/assets/utils/exportExcel";
import Import from "../storeMember/comments/import";
export default {
  name: "ProductExamine1",
  components: {
    proDetail,
    Import,
  },
  data() {
    return {
      importShow: false,
      importAction: "",
      importDownloadLink: "",
      importProblem: "",
      tableFrom: {
        pageNum: 1,
        pageSize: 20,
        checkStatus: "-1",
        classifyId: [],
        name: "",
        status: "",
        brandId: "",
        vendorId: "",
        pushType: "",
        classifyType: "",
      },
      merCateList: [],
      listLoading: false,
      tableData: {
        checkStatus: "1",
      },
      productStatusList: [
        {
          label: "上架",
          value: 1,
        },
        {
          label: "下架",
          value: 0,
        },
      ],
      statusOptions: [
        {
          label: "是",
          value: 1,
        },
        {
          label: "否",
          value: 0,
        },
      ],
      drawer: false,
      product_id: "",
      classifyList: [], //分类
      supplierList: [], //供应商
      brandList: [], //品牌列表
      selectionId: [], //选中得id
      dialogVisible: false, //控制审核弹框
      checkStatus: 1, //	审核状态 1-审核通过；2-审核未通过
      e3: true, //是否为e3商品
      checkReason: "", //	审核未通过原因
      tabList: [
        {
          id: "-1",
          name: "全部",
        },
        {
          id: "1",
          name: "出售的商品",
        },
        {
          id: "0",
          name: "待审核商品",
        },
        {
          id: "2",
          name: "审核未通过商品",
        },
      ],
    };
  },
  mounted() {
    this.getList();
    this.getClassifyList();
    this.getSupplierList();
    this.getBrandList();
    // this.getMerSelect()
    // this.getList()
    // this.getCategorySelect()
    // this.getLstFilterApi()
    // this.getLabelLst()
  },
  methods: {

    exportExamineGoods(){
      
      this.importAction = "/api/goods/web/goods/platform/batch/check";
      this.importDownloadLink = "./商品批量审核导入模板.xlsx";
      this.importProblem = "/api/goods/web/goods/check/error/export";
      this.importShow = true;
    },
    exportGoods() {
      this.importAction = "/api/goods/web/goods/platform/importGoods";
      this.importDownloadLink = "/api/goods/web/goods/template/export";
      this.importProblem = "/api/goods/web/goods/platform/error/export";
      this.importShow = true;
    },
    // 导入弹框关闭
    importClose(e) {
      this.importShow = false;
      if (e === 1 || e === 2) {
        this.getList();
      }
    },
    //导出
    exportFun() {
      exportExcel("/goods/web/goods/platform/pageList/export", {
        ...this.tableFrom,
        time: "",
        pageNum: 1,
        responseType: "blob",
        exportExcelName: "商品列表",
      });
    },
    handleClose() {
      this.checkStatus = 1;
      this.checkReason = "";
      this.e3 = true;
      this.dialogVisible = false;
    },
    onchangeStatus(row) {
      goodsShelve({
        ids: [row.id],
        status: row.status,
      })
        .then((res) => {
          if (res.code === 200) {
            this.$message.success("操作成功");
            this.getList();
          }
        })
        .catch(({ msg }) => {
          this.getList();
        });
    },
    // //是否首页展示
    // onchangeRecommendBrand(row){
    //   goodsSet({ id: row.id, status: row.isRecommendBrand })
    //     .then(({ msg }) => {
    //       this.getList();
    //       this.$message.success(msg);
    //     })
    //     .catch(({ msg }) => {
    //       this.getList();
    //     });
    // },
    //审核状态改变事件
    checkStatusChange(e) {
      this.checkReason = "";
    },
    //批量审核
    batchExamine() {
      if (this.selectionId.length == 0) {
        this.$message.error("请选择操作数据");
        return;
      }
      this.dialogVisible = true;
    },
    //审核确定按钮
    checkSumbit() {
      if (this.checkStatus == 2 && !this.checkReason) {
        this.$message.error("请输入审核未通过原因");
        return;
      }
      goodsCheck({
        ids: this.selectionId,
        e3: this.e3,
        checkStatus: this.checkStatus,
        checkReason: this.checkReason,
      }).then((res) => {
        if (res.code === 200) {
          this.getList();
          this.$message.success("操作成功");
          this.dialogVisible = false;
          that.getList();
        }
      });
    },
    toExamine(id) {
      this.selectionId = [id];
      this.dialogVisible = true;
    },
    //批量上架
    batchUp(val) {
      if (this.selectionId.length == 0) {
        this.$message.error("请选择操作数据");
        return;
      }
      goodsShelve({
        ids: this.selectionId,
        status: val,
      }).then((res) => {
        if (res.code === 200) {
          this.$message.success("操作成功");
          this.getList();
        }
      });
    },
    //批量下架
    batchDown(val) {
      if (this.selectionId.length == 0) {
        this.$message.error("请选择操作数据");
        return;
      }
      goodsShelve({
        ids: this.selectionId,
        status: val,
      }).then((res) => {
        if (res.code === 200) {
          this.$message.success("操作成功");
          this.getList();
        }
      });
    },
    //多选得id
    handleSelectionChange(val) {
      console.log("123", val);
      this.multipleSelection = val;
      const data = [];
      this.multipleSelection.map((item) => {
        data.push(item.id);
      });
      this.selectionId = data;
    },
    handleSizeChange(val) {
      this.tableFrom.pageSize = val;
      this.getList();
    },
    pageChange(val) {
      this.tableFrom.pageNum = val;
      this.getList();
    },
    //获取商品分类列表
    getClassifyList() {
      goodsClassifyList({}).then((res) => {
        this.classifyList = res.data;
      });
    },
    //获取供应商列表
    getSupplierList() {
      getSupplierListAll({}).then((res) => {
        this.supplierList = res.data;
      });
    },
    //获取品牌列表
    getBrandList() {
      goodBrandTotalListAll(1).then((res) => {
        this.brandList = res.data;
      });
    },
    tabClick() {
      this.tableFrom.pageNum = 1;
      this.getList();
    },
    search() {
      this.tableFrom.pageNum = 1;
      this.getList();
    },
    searchReset() {
      this.tableFrom.pageNum = 1;
      this.$refs.searchForm.resetFields();
      this.getList();
    },
    getList() {
      this.listLoading = true;
      //type 1 一级分类 2 二级分类 3 三级分类
      if (this.tableFrom.classifyId) {
        this.classifyList.map((item) => {
          if (item.id == this.tableFrom.classifyId) {
            this.tableFrom.classifyType = 1;
          }
          if (item.children) {
            item.children.map((items) => {
              if (items.id == this.tableFrom.classifyId) {
                this.tableFrom.classifyType = 2;
              }
              if (items.children) {
                items.children.map((itemss) => {
                  if (itemss.id == this.tableFrom.classifyId) {
                    this.tableFrom.classifyType = 3;
                  }
                });
              }
            });
          }
        });
      } else {
        this.tableFrom.type = "";
      }
      goodsPageList(this.tableFrom)
        .then((res) => {
          this.tableData.data = res.data.records;
          this.tableData.total = res.data.total;
          this.listLoading = false;
        })
        .catch((res) => {
          this.listLoading = false;
        });
    },
    changeDrawer(v) {
      this.drawer = v;
    },
    closeDrawer() {
      this.drawer = false;
    },
    //商品详情
    onDetails(id) {
      this.product_id = id;
      this.drawer = true;
      this.$refs.proDetail.getInfo(id);
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/.el-select-dropdown__item {
  max-width: 350px !important;
}
.template {
  overflow: hidden;
}
.label-list {
  height: 100%;
}
.bg {
  z-index: 100;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
}
.tags_name {
  font-size: 10px;
  height: 16px;
  line-height: 16px;
  padding: 0 2px;
  margin-right: 2px;
  &.name0 {
    color: var(--prev-color-primary);
  }
  &.name1 {
    color: #ff8a4d;
  }
}
.rate_star {
  position: relative;
  top: 5px;
}
table .el-image {
  display: inline-block;
}
.demo-table-expand {
  font-size: 0;
}
.demo-table-expand {
  /deep/ label {
    width: 105px;
    color: #99a9bf;
  }
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 33.33%;
}
.seachTiele {
  line-height: 35px;
}
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
  font-size: 12px;
}

.el-icon-arrow-down {
  font-size: 12px;
}
</style>
